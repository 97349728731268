import { Badge } from "@metaforcelabs/metaforce-core";
import { useDialogValuesStatusHelper } from "../../hooks/useDialogValuesStatusHelper";

export const DialogValuesStatusBadge = ({
  record,
  isMultistep
}) => {

  const dialogStatus = useDialogValuesStatusHelper(record);

  const renderBadge = () => {
    if (dialogStatus.isComplete)
      return <Badge type="success" text="Complete" />
    else if (dialogStatus.isCompleteSigned)
      return <Badge type="success" text="Complete and signed" />
    else if (dialogStatus.isAwaitingSigning)
      return <Badge type="info" text="Awaiting Signing" />

    const stepName = isMultistep ?
      (record.currentStep?.stepName ? record.currentStep?.stepName : 'N/A') : 'In proggress';

    return <Badge type="default" text={stepName} />
  }

  return (
    <>
      {renderBadge()}
    </>
  );
}