import React from 'react';
import { config } from '../../utils/config';
import { MenuBar } from '@metaforcelabs/metaforce-core';

const MissingLicense = () => {
  return (
    <div className="relative min-h-screen flex flex-col bg-white">
      <MenuBar
        subMenuNav={'releasePackages'}
        homeButtonLink={config?.centerpointUiBaseUrl}
      />

      <div className="pt-2 pb-10 flex bg-gray-50 h-screen">
        <div className={'flex  w-full mx-auto '}>
          <p className="text-2xl mt-6 ml-6">Missing license to access SmartForms - Admin</p>
        </div>
      </div>
    </div>
  )
}
export default MissingLicense;